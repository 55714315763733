import React from "react";
import { Container, Navbar } from "react-bootstrap";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import logo from "./Image/logo.jpg";

function App() {
  return (
    <>
      <Navbar expand="lg" bg="light" className="navbar-custom shadow">
        <Container fluid>
          <Navbar.Brand href="#" className="m-0 p-0">
            <img
              src={logo}
              alt="Dhyan Abhiyan Ashram Logo"
              width="50"
              height="50"
              className="me-2"
            />
            <span className="logotxt">Dhyan Abhiyan Ashram</span>
          </Navbar.Brand>
          <div className="d-flex flex-wrap text-end contact-info">
            <div className="headercontact1">
              <span>
                <i className="bi bi-telephone-fill"></i>
              </span>
              
            </div>
            <div className="headercontact2">              
              
            </div>
          </div>
        </Container>
      </Navbar>

      <section className="background-section">
        <div className="overlay">
          <h1 className="titletext">
            <span className="titletextspan"> Coming Soon </span> Dhyan Abhiyan
            Ashram
          </h1>
          <p className="linetext">Find peace and mindfulness with us.</p>

          <div className="social-menu">
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/@nityamala"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-youtube"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/people/Nitya-Mala/61570826912508/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.nitya.mala.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="bi bi-google"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
